import { NavBar } from "@shared/components/navbar/navbar";
import { GetStaticPropsContext, NextPage } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { GetStaticProps } from "next/types";
import React from "react";

const Error404: NextPage = () => {
    return (
        <>
            <Head>
                <title>404 | UXF</title>
            </Head>
            <NavBar />
            <main
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    padding: "1em",
                    textAlign: "center",
                }}
            >
                <h1 style={{ fontSize: "4em" }}>404</h1>
                <p style={{ fontSize: "2em" }}>Stránka nenalezena / Page not found</p>
            </main>
        </>
    );
};

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
    const { locale } = context;

    return {
        props: {
            ...(await serverSideTranslations(locale ?? "cs", ["common"])),
        },
    };
};

export default Error404;
